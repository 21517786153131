import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
import { Box } from 'fannypack';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box height="80vh" mdxType="Box">
	<iframe src="https://playground.medipass.io" width="100%" height="100%" style={{
        border: '1px solid #d9d9d9'
      }} />
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      